<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Riwayat</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">
          Tambah Riwayat Hukuman Disiplin
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> Jenis Tingkat Hukuman Disiplin </span>

              <v-select
                v-model="jenhukdis"
                class="mt-2"
                :items="refjenhuk"
                item-value="id"
                item-text="nama"
                return-object
                outlined
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <span> Tingkat Hukuman Disiplin </span>

              <v-text-field
                v-model="tinghukdis"
                class="mt-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> No SK Hukuman Disiplin </span>

              <v-text-field
                v-model="noskhukdis"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <span> Nomor Peraturan </span>

              <v-select
                v-model="noper"
                class="mt-2"
                :items="refnoper"
                item-value="id"
                item-text="nama"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> Alasan Hukuman </span>

              <v-autocomplete
                v-model="alasanhukdis"
                class="mt-2"
                :items="refalasan"
                item-value="id"
                item-text="nama"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <span> Tanggal SK Hukuman Disiplin </span>

              <v-menu
                ref="picktgl"
                v-model="picktgl"
                :close-on-content-click="false"
                :return-value.sync="tglskhukdis"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="tglskhukdis"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="tglskhukdis" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="picktgl = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.picktgl.save(tglskhukdis)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> Masa Hukuman Tahun </span>

              <v-text-field
                v-model="mhtahun"
                class="mt-2"
                outlined
                dense
                maxlength="2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <span> TMT Hukuman Disiplin </span>

              <v-menu
                ref="picktmt"
                v-model="picktmt"
                :close-on-content-click="false"
                :return-value.sync="tmthukdis"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="tmthukdis"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="tmthukdis" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="picktmt = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.picktmt.save(tmthukdis)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> Masa Hukuman Bulan </span>

              <v-text-field
                v-model="mhbulan"
                class="mt-2"
                outlined
                dense
                maxlength="2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <span> Keterangan </span>

              <v-text-field
                v-model="keterangan"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <strong> Upload Dokumen </strong>

              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onUpload"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-divider class="mb-5"></v-divider>

          <div class="text-right">
            <v-btn
              color="primary"
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/asn/rwhukdis/viewRwhukdis";

export default {
  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    ModalAdd: false,
    lbtn: false,
    picktgl: false,
    picktmt: false,
    get_nip: "",

    refnoper: [],
    refjenhuk: [],
    refalasan: [],

    jenhukdis: "",
    tinghukdis: "",
    noskhukdis: "",
    noper: "",
    alasanhukdis: "",
    tglskhukdis: "",
    mhtahun: "",
    tmthukdis: "",
    mhbulan: "",
    keterangan: "",
    doc: "",

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],
  }),

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refnoper = await getRef.noper(this.token);
        this.refjenhuk = await getRef.jenhuk(this.token);
        this.refalasan = await getRef.alasan(this.token);
      }
    },

    jenhukdis(value) {
      if (value.tingkat == "R") {
        this.tinghukdis = "Ringan";
      } else if (value.tingkat == "S") {
        this.tinghukdis = "Sedang";
      } else if (value.tingkat == "B") {
        this.tinghukdis = "Berat";
      }
    },
  },

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    reset() {},

    async simpan() {
      this.lbtn = true;

      if (this.validate()) {
        const url = process.env.VUE_APP_ASN + "hukdis/nip/" + this.get_nip;

        const data = new FormData();
        data.append("doc", this.doc);
        data.append("jenhukdis", this.jenhukdis.id);
        data.append("tinghukdis", this.jenhukdis.tingkat);
        data.append("noskhukdis", this.noskhukdis);
        data.append("noper", this.noper);
        data.append("alasanhukdis", this.alasanhukdis);
        data.append("tglskhukdis", this.tglskhukdis);
        data.append("mhtahun", this.mhtahun);
        data.append("tmthukdis", this.tmthukdis);
        data.append("mhbulan", this.mhbulan);
        data.append("keterangan", this.keterangan);

        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.lbtn = false;
            this.closeModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.lbtn = false;
              this.closeModal();
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.lbtn = false;
              this.closeModal();
            }
          });
      } else {
        window.scrollTo(0, 0);
        this.lbtn = false;
      }
    },

    onUpload(value) {
      this.doc = value;
    },

    closeModal() {
      this.reset();
      this.ModalAdd = false;
    },
  },
};
</script>
