a
<template>
  <v-dialog v-model="ModalHapus" persistent max-width="40%">
    <v-card>
      <v-card-title class="headline">
        Apakah Anda Yakin Akan Menghapus?
      </v-card-title>
      <v-card-text>
        Data yang dihapus tidak dapat dikembalikan lagi!
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" small depressed @click="ModalHapus = false">
          Batal
        </v-btn>
        <v-btn
          v-if="btnLoading"
          color="green"
          small
          depressed
          dark
          @click="hapus()"
        >
          Ya
        </v-btn>
        <v-btn v-else color="green" small depressed dark loading> Ya </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from '@/helper/cookie.js'
import apiURL from '@/helper/getURL.js'
import Auth from '@/helper/auth.js'

import refreshView from '@/store/asn/rwhukdis/viewRwhukdis'
import modal_hapus from '@/store/asn/rwhukdis/modal_hapus'

export default {
  data() {
    return {
      token: Cookie.get('token'),
      token_siasn: Cookie.get('token_siasn'),
      token_wsbkn: Cookie.get('token_wsbkn'),

      btnLoading: true,
      get_nip: '',
    }
  },
  computed: {
    ModalHapus: {
      get() {
        return modal_hapus.state.ModalHapus
      },

      set(value) {
        modal_hapus.commit('toggleModal', value)
      },
    },

    dItem: {
      get() {
        return modal_hapus.state.rwhukdis
      },

      set(value) {
        console.log(value)
      },
    },
  },

  async mounted() {
    this.get_nip = this.$route.params.id
  },

  methods: {
    hapus() {
      this.btnLoading = false

      let data = {
        id: this.dItem.id,
      }

      const url = apiURL.baseURL + 'hukdis/nip/' + this.get_nip

      this.http
        .delete(url, {
          headers: {
            Authorization: 'Bearer ' + this.token,
          },
          data: data,
        })
        .then((response) => {
          this.btnLoading = true
          if (response.data.success) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('berhasilAlert', true)
            refreshView.commit('gagalAlert', false)
            refreshView.commit('success', response.data.success)
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', response.data.success)
          }
          this.ModalHapus = false
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', error.response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', error.response.data.success)
            console.log(error.response.status)
            this.ModalHapus = false
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', error.response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', error.response.data.success)
            console.log(error.response.status)
            this.ModalHapus = false
          }
        })
    },
  },
}
</script>
