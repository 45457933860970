<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">
          Edit Riwayat Hukuman Disiplin
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> Jenis Tingkat Hukuman Disiplin </span>

              <v-select
                v-model="eItem.jenis_hukdis.id"
                class="mt-2"
                :items="refjenhuk"
                item-value="id"
                item-text="nama"
                return-object
                outlined
                dense
                @change="cjenhuk"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <span> Tingkat Hukuman Disiplin </span>

              <v-text-field
                v-model="eItem.tingkat_hukdis"
                class="mt-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> No SK Hukuman Disiplin </span>

              <v-text-field
                v-model="eItem.noskhukdis"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <span> Nomor Peraturan </span>

              <v-select
                v-model="eItem.noper_hukdis.id"
                class="mt-2"
                :items="refnoper"
                item-value="id"
                item-text="nama"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> Alasan Hukuman </span>

              <v-autocomplete
                v-model="eItem.alasan_hukdis.id"
                class="mt-2"
                :items="refalasan"
                item-value="id"
                item-text="nama"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <span> Tanggal SK Hukuman Disiplin </span>

              <v-menu
                ref="picktgl"
                v-model="picktgl"
                :close-on-content-click="false"
                :return-value.sync="eItem.tglhukdis"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eItem.tglhukdis"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eItem.tglhukdis" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="picktgl = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.picktgl.save(eItem.tglhukdis)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> Masa Hukuman Tahun </span>

              <v-text-field
                v-model="eItem.masa_hukuman_tahun"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <span> TMT Hukuman Disiplin </span>

              <v-menu
                ref="picktmt"
                v-model="picktmt"
                :close-on-content-click="false"
                :return-value.sync="eItem.tmthukdis"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eItem.tmthukdis"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eItem.tmthukdis" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="picktmt = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.picktmt.save(eItem.tmthukdis)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="6">
              <span> Masa Hukuman Bulan </span>

              <v-text-field
                v-model="eItem.masa_hukuman_bulan"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <span> Keterangan </span>

              <v-text-field
                v-model="eItem.keterangan"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <strong> Upload Dokumen </strong>

              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onUpload"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-divider class="mb-5"></v-divider>

          <div class="text-right">
            <v-btn
              color="primary"
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/asn/rwhukdis/viewRwhukdis";
import modal_edit from "@/store/asn/rwhukdis/modal_edit";

export default {
  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    modalPDF: false,
    lbtn: false,
    picktgl: false,
    picktmt: false,

    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    get_nip: "",
    doc: "",
    jenhukdis: {
      id: "",
      nama: "",
      tingkat: "",
    },

    refnoper: [],
    refjenhuk: [],
    refalasan: [],

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],
  }),

  watch: {
    async ModalEdit() {
      this.refnoper = await getRef.noper(this.token);
      this.refjenhuk = await getRef.jenhuk(this.token);
      this.refalasan = await getRef.alasan(this.token);
    },

    eItem() {
      if (this.eItem.tingkat_hukdis == "R") {
        this.eItem.tingkat_hukdis = "Ringan";
      } else if (this.eItem.tingkat_hukdis == "S") {
        this.eItem.tingkat_hukdis = "Sedang";
      } else if (this.eItem.tingkat_hukdis == "B") {
        this.eItem.tingkat_hukdis = "Berat";
      }
    },
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_edit.state.ModalEdit;
      },

      set(value) {
        modal_edit.commit("toggleModal", value);
      },
    },

    eItem: {
      get() {
        return modal_edit.state.rwhukdis;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    cjenhuk(value) {
      if (value.tingkat == "R") {
        this.jenhukdis = value;
        this.eItem.tingkat_hukdis = "Ringan";
      } else if (value.tingkat == "S") {
        this.jenhukdis = value;
        this.eItem.tingkat_hukdis = "Sedang";
      } else if (value.tingkat == "B") {
        this.jenhukdis = value;
        this.eItem.tingkat_hukdis = "Berat";
      }
    },

    onUpload(value) {
      this.doc = value;
    },

    async simpan() {
      this.lbtn = true;

      if (this.validate()) {
        const url = process.env.VUE_APP_ASN + "hukdis/nip/" + this.get_nip;

        const data = new FormData();
        data.append("idhukdis", this.eItem.id);
        data.append("doc", this.doc);
        data.append(
          "jenhukdis",
          this.jenhukdis.id ? this.jenhukdis.id : this.eItem.jenis_hukdis.id
        );
        data.append(
          "tinghukdis",
          this.jenhukdis.tingkat
            ? this.jenhukdis.tingkat
            : this.eItem.jenis_hukdis.tingkat
        );
        data.append("noskhukdis", this.eItem.noskhukdis);
        data.append("noper", this.eItem.noper_hukdis.id);
        data.append("alasanhukdis", this.eItem.alasan_hukdis.id);
        data.append("tglskhukdis", this.eItem.tglhukdis);
        data.append("mhtahun", this.eItem.masa_hukuman_tahun);
        data.append("tmthukdis", this.eItem.tmthukdis);
        data.append("mhbulan", this.eItem.masa_hukuman_bulan);
        data.append("keterangan", this.eItem.keterangan);

        this.http
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.lbtn = false;
            this.closeModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.lbtn = false;
              this.closeModal();
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.lbtn = false;
              this.closeModal();
            }
          });
      } else {
        window.scrollTo(0, 0);
        this.lbtn = false;
      }
    },

    closeModal() {
      this.ModalEdit = false;
    },
  },
};
</script>
