import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },

    rwhukdis: {
      id: "",
      jenis_hukdis: {
        id: 0,
        nama: "",
        tingkat: ""
      },
      tingkat_hukdis: "",
      noskhukdis: "",
      noper_hukdis: {
        id: 1,
        nama: ""
      },
      alasan_hukdis: {
        id: ""
      },
      tglhukdis: "",
      masa_hukuman_tahun: "",
      tmthukdis: "",
      masa_hukuman_bulan: "",
      keterangan: "",
      repo_882: "",
      userId: "",
      NCSISTIME: ""
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwhukdis = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})