<template>
  <div>
    <v-row class="mb-n10">
      <v-col cols="12" md="6">
        <span> Jenis Tingkat Hukuman Disiplin </span>

        <v-text-field
          v-model="vitem.jenis_hukdis.nama"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <span> Tingkat Hukuman Disiplin </span>

        <v-text-field
          v-model="tinghuk"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="6">
        <span> No SK Hukuman Disiplin </span>

        <v-text-field
          v-model="vitem.noskhukdis"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <span> Nomor Peraturan </span>

        <v-text-field
          v-model="vitem.noper_hukdis.nama"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="6">
        <span> Alasan Hukuman </span>

        <v-textarea
          v-model="vitem.alasan_hukdis.nama"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <span> Tanggal SK Hukuman Disiplin </span>

        <v-text-field
          v-model="vitem.tglhukdis"
          append-icon="mdi-calendar"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="6">
        <span> Masa Hukuman Tahun </span>

        <v-text-field
          v-model="vitem.masa_hukuman_tahun"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <span> TMT Hukuman Disiplin </span>

        <v-text-field
          v-model="vitem.tmthukdis"
          append-icon="mdi-calendar"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <span> Masa Hukuman Bulan </span>

        <v-text-field
          v-model="vitem.masa_hukuman_bulan"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <span> Keterangan </span>

        <v-text-field
          v-model="vitem.keterangan"
          class="mt-2"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  props: ["vitem"],

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),
  }),

  computed: {
    tinghuk() {
      if (this.vitem.jenis_hukdis.tingkat == "R") {
        return "Ringan";
      } else if (this.vitem.jenis_hukdis.tingkat == "S") {
        return "Sedang";
      } else if (this.vitem.jenis_hukdis.tingkat == "B") {
        return "Berat";
      } else {
        return "";
      }
    },
  },
};
</script>
